<template>
  <div class="th-list-course">
    <div class="row">
      <div class=" col-12 col-xl-9" style="border-radius: 40px;">
        <!-- WIDGET -->
        <div class="card px-5 py-4" style="border-radius: 40px">
          <div class="row" style="background-color: #fff;">
            <div class="col-12 col-md-6 col-xl-4">
              <div class="d-flex">
                <div>
                  <div class="d-flex align-items-center">
                    <span class="font-custom3">
                      <img src="../../assets/img/total-pasien.png" alt="" style="width: 40px;height: 40px;">
                    </span>
                    <span style="font-size: 20px" class="font-custom3 ml-2">Total Pasien</span>
                  </div>
                  <div class="mt-4">
                    <p style="font-size: 40px;line-height: 1;color: #3C1053;margin: 0 !important;" class="font-custom">
                      {{
                        totalPasien }}
                    </p>
                  </div>
                </div>
                <a-divider type="vertical"
                  style="height: 110px; width: 1px; background-color: #E1C1D4;margin-left: 40px;"
                  class="d-none d-sm-block" />
              </div>
            </div>
            <div class="col-12 col-md-6 col-xl-4 mt-5 mt-md-0">
              <div class="d-flex">
                <div class="w-100">
                  <div class="d-flex align-items-center">
                    <span class="font-custom3">
                      <img src="../../assets/img/rataskor.png" alt="" style="width: 40px;height: 40px;">
                    </span>
                    <span style="font-size: 20px" class="font-custom3 ml-2">Rata-rata Skor</span>
                  </div>
                  <div class="mt-4">
                    <p style="font-size: 40px;line-height: 1;color: #3C1053;margin: 0 !important;" class="font-custom">
                      {{
                        rataSkor }}
                    </p>
                  </div>
                </div>

              </div>
            </div>
            <div class="col-12 col-md-6 col-xl-4 mt-5 mt-md-5 mt-xl-0 d-flex">
              <a-divider type="vertical" class="d-none d-xl-block"
                style="height: 110px; width: 1px; background-color: #E1C1D4;margin-right: 30px;" />
              <div>
                <div class="d-flex align-items-center">
                  <span class="font-custom3">
                    <img src="../../assets/img/pending-verifikasi.png" alt="" style="width: 40px;height: 40px;">
                  </span>
                  <span style="font-size: 20px" class="font-custom3 ml-2">Pending Verifikasi</span>
                </div>
                <div class="mt-4">
                  <p style="font-size: 40px;line-height: 1;color: #3C1053;margin: 0 !important;" class="font-custom">{{
                    pendingVerifikasi }}
                  </p>
                </div>
              </div>

            </div>
          </div>
        </div>
        <!-- DEMOGRAFI -->
        <div class="row">
          <div class="col-12">
            <div class="card p-5" style="border-radius: 40px; height: 450px;">
              <p class="font-custom" style="font-size: 24px;">Demografi Pasien Berdasarkan Kota</p>
              <div class="d-flex flex-column align-items-center justify-content-center">
                <div style="width: 100%; height: 300px" class="d-none d-xl-block">
                  <canvas id="myChart5"></canvas>
                </div>
                <div style="width: 100%;height: 300px;" class="d-block d-xl-none">
                  <canvas id="myChart4"></canvas>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 ">
            <div class="row">
              <div class="col-12 col-xl-6 d-inline-block">
                <div class="card d-flex d-xl-block align-items-center" style="border-radius: 40px">

                  <!-- <h2 class="text-uppercase text-dark font-weight-bold mb-1 text-center"> {{ dataAggregate.totalAsset }}
                  </h2>
                  <p class="text-gray-5 text-center mb-0">Jumlah seluruh aset</p> -->
                  <div class=" px-5 pt-5">
                    <h4 class="text-dark font-weight-bold mb-1">Rentang Umur</h4>

                  </div>
                  <!-- <p class="text-center font-custom" style="font-size: 26px;">Total Asset</p> -->
                  <div class="d-flex align-items-center justify-content-center px-4 mb-5">
                    <div style="width: 180px; height: 180px;">
                      <canvas id="myChart"></canvas>
                    </div>
                    <div class="w-100 h-100">
                      <div style="font-family: Arial, sans-serif; font-size: 14px;"
                        class=" d-flex align-items-start ml-5 flex-column">
                        <div class="d-flex justify-content-center align-items-center">
                          <div style="display: flex; align-items: center; margin-bottom: 4px;">
                            <div
                              style="width: 16px; height: 16px;border-radius: 5px; background-color: #E1C1D4; margin-right: 5px;">
                            </div>
                            <div class="font-custom3 ml-1">
                              {{ strRentangUmur.pertama }}
                            </div>
                          </div>
                        </div>
                        <div class="d-flex justify-content-center align-items-center mt-3">
                          <div style="display: flex; align-items: center; margin-bottom: 4px;">
                            <div
                              style="width: 16px; height: 16px;border-radius: 5px; background-color: #FDB752; margin-right: 5px;">
                            </div>
                            <div class="font-custom3 ml-1">{{ strRentangUmur.kedua }}</div>

                          </div>
                        </div>
                        <div class="d-flex justify-content-center align-items-center mt-3">
                          <div style="display: flex; align-items: center; margin-bottom: 4px;">
                            <div
                              style="width: 16px; height: 16px;border-radius: 5px; background-color: #830051; margin-right: 5px;">
                            </div>
                            <div class="font-custom3 ml-1">{{ strRentangUmur.ketiga }}</div>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
              <div class="col-12 col-xl-6 d-inline-block">
                <div class="card d-flex d-xl-block align-items-center" style="border-radius: 40px">

                  <!-- <h2 class="text-uppercase text-dark font-weight-bold mb-1 text-center"> {{ dataAggregate.totalAsset }}
                  </h2>
                  <p class="text-gray-5 text-center mb-0">Jumlah seluruh aset</p> -->
                  <div class=" px-5 pt-5">
                    <h4 class="text-dark font-weight-bold mb-1">Jenis Kelamin</h4>

                  </div>
                  <!-- <p class="text-center font-custom" style="font-size: 26px;">Total Asset</p> -->
                  <div class="d-flex align-items-center justify-content-center px-5 mb-5">
                    <div style="width: 180px; height: 180px;">
                      <canvas id="myChart2"></canvas>
                    </div>
                    <div class="w-100 h-100">
                      <div style="font-family: Arial, sans-serif; font-size: 14px;"
                        class=" d-flex align-items-start ml-0 ml-xl-5 flex-column">
                        <div class="d-flex justify-content-center align-items-center">
                          <div style="display: flex; align-items: center; margin-bottom: 4px;">
                            <div
                              style="width: 16px; height: 16px;border-radius: 5px; background-color: #E1C1D4; margin-right: 5px;">
                            </div>
                            <div class="font-custom3 ml-1">
                              Laki Laki
                            </div>
                          </div>
                        </div>
                        <div class="d-flex justify-content-center align-items-center mt-3">
                          <div style="display: flex; align-items: center; margin-bottom: 4px;">
                            <div
                              style="width: 16px; height: 16px;border-radius: 5px; background-color: #830051; margin-right: 5px;">
                            </div>
                            <div class="font-custom3 ml-1">Perempuan</div>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>


        </div>
      </div>
      <div class="col-12 col-xl-3">
        <div class="card py-5" style="border-radius: 40px;">
          <div class="d-block d-md-flex flex-row-reverse align-items-center justify-content-center d-xl-block">
            <div class="d-flex align-items-center justify-content-center" style="margin-top: 40px;">
              <img src="../../assets/img/obat.png" alt="" style="height: 165px; width: 191px; ">
            </div>
            <div class="d-flex flex-column align-items-center justify-content-center">
              <div class="p-5">
                <h3>Pasien Tiering</h3>
                <div class="d-flex mt-4">
                  <img src="../../assets/img/silver.png" alt="" style="height: 57px; width: 57px; ">
                  <div class="d-flex flex-column align-items-start justify-content-center ml-4">
                    <p class="m-0 tieringList">{{ patientTiering['-1'] }}</p>
                    <div class="d-flex align-items-baseline mt-2">
                      <p class="m-0 ml-1">pasien</p>
                    </div>
                  </div>
                </div>
                <div class="d-flex mt-4">
                  <img src="../../assets/img/gold.png" alt="" style="height: 57px; width: 57px; ">
                  <div class="d-flex flex-column align-items-start justify-content-center ml-4">
                    <p class="m-0 tieringList">{{ patientTiering['0'] }}</p>
                    <div class="d-flex align-items-baseline mt-2">
                      <p class="m-0 ml-1">pasien</p>
                    </div>
                  </div>
                </div>
                <div class="d-flex mt-4">
                  <img src="../../assets/img/platinum.png" alt="" style="height: 57px; width: 57px; ">
                  <div class="d-flex flex-column align-items-start justify-content-center ml-4">
                    <p class="m-0 tieringList">{{ patientTiering['1'] }}</p>
                    <div class="d-flex align-items-baseline mt-2">
                      <p class="m-0 ml-1">pasien</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import { onMounted, onBeforeUnmount, ref, reactive, createVNode, toRef, toRefs, watch, computed } from 'vue'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import { baseURL } from '@/services/axios'
import {
  CheckOutlined,
  ToolOutlined,
  PhoneOutlined,
  CloudUploadOutlined,
  CloudDownloadOutlined,
  InfoCircleOutlined,
  HomeOutlined,
  ContactsOutlined,
} from '@ant-design/icons-vue'
import rupiahPrice from '@/helpers/rupiahPrice'
import {
  getAddress,
  getAll,
  searchPotensi,
  remove,
  updateNasabah,
  getAllKanwil,
  getOneKC,
  getDistinct,
} from '@/services/axios/brivice-backend'
import fuzzysort from 'fuzzysort'
import { Modal, notification, message } from 'ant-design-vue'
import { useForm } from '@ant-design-vue/use'
import axios from 'axios'
import moment from 'moment'
import lodash from 'lodash'
import assetsFile from '@/data/modc_dump.json'
import { getAssets, getCustomer } from '../../services/axios/modc/assets'
import { getDashboardData } from '../../services/axios/modc/dashboard'
import { getRooms, getLocationByCustomerId } from '../../services/axios/modc/customer'
import { getLocations } from '../../services/axios/modc/users'
import lottie from '../../../scripts/Timer.json'
import caution from '../../../scripts/Caution.json'

import {
  getVisitors, outVisitor, VisitorIn, cancelVisitors,
} from '../../services/axios/modc/receptionist'
// CHART
import { Chart, registerables } from "chart.js";
import { Vue3Lottie } from 'vue3-lottie'
const legendPaddingPlugin = {
  id: 'legendPaddingPlugin',
  beforeInit(chart) {
    const fitValue = chart.legend.fit;
    chart.legend.fit = function fit() {
      fitValue.bind(chart.legend)();
      chart.legend.height += 20; // Menyesuaikan ini sesuai dengan jumlah padding yang Anda inginkan
    };
  }
};
Chart.register(...registerables, legendPaddingPlugin);
const columns = [
  {
    title: 'ID',
    dataIndex: 'name',
    key: 'name',
    slots: { customRender: 'name' },
    width: 100,
    sorter: true,
  },
  {
    title: 'LOKASI',
    dataIndex: 'location',
    key: 'location',
    slots: { customRender: 'location' },
  },
  {
    title: 'MANUFAKTUR',
    dataIndex: 'manufacture',
    key: 'manufacture',
    slots: { customRender: 'manufacture' },
  },
  {
    title: 'CUSTOMER',
    dataIndex: 'customer',
    key: 'customer',
    slots: { customRender: 'customer' },
  },
  {
    title: 'STATUS GARANSI',
    dataIndex: 'status',
    key: 'status',
    slots: { customRender: 'status' },
  },
  {
    title: 'STATUS ASSET',
    dataIndex: 'status',
    key: 'status',
    slots: { customRender: 'statusAsset' },
  },

  {
    title: 'NILAI ASET',
    dataIndex: 'assetValue',
    key: 'assetValue',
    slots: { customRender: 'assetValue' },
  },
]
const columnsReceptionist = [
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
    slots: { customRender: 'name' },
  },


  {
    title: 'Vendor',
    dataIndex: 'Vendor.name',
    key: 'category',
    // slots: { customRender: 'description' },
  },
  {
    title: 'Status',
    key: 'out',
    align: 'center',
    slots: { customRender: 'status' },
  },
  // {
  //   title: 'PIC',
  //   dataIndex: 'PIC',
  //   key: 'category',
  //   // slots: { customRender: 'description' },
  // },
  // {
  //   title: 'PIC',
  //   dataIndex: 'PIC',
  //   key: 'category',
  //   // slots: { customRender: 'description' },
  // },
  // {
  //     title: 'Keterangan',
  //     key: 'ceklis',
  //     slots: { customRender: 'action' },
  //     align: 'center',
  // },
];

export default {
  components: {
    CloudUploadOutlined,
    CloudDownloadOutlined,
    InfoCircleOutlined,
    CheckOutlined,
    ToolOutlined,
    PhoneOutlined,
    HomeOutlined,
    ContactsOutlined,
    Vue3Lottie,
    // BarChart
  },
  setup() {
    /* eslint-disable */
    let store = useStore()
    let settings = computed(() => store.getters.settings)

    let strRentangUmur = ref({
      pertama: '< 40 tahun',
      kedua: '41-50 tahun',
      ketiga: '> 50 tahun',
    })
    const totalPasien = ref(400)
    const rataSkor = ref(8.7)
    const pendingVerifikasi = ref(2)
    const patientTiering = ref({
      '-1': 127,
      '0': 22,
      '1': 9,
    })

    const chartInstance = ref(null);
    onMounted(async () => {
      // const storedUserData = localStorage.getItem('userData')
      // const userData = JSON.parse(storedUserData)
      // role.value = userData.user.role
      const ctx = document.getElementById('myChart');
      // console.log(dataAggregate.value)
      new Chart(ctx, {
        type: 'doughnut',
        data: {
          labels: [
            'Active Devices',
            'Passive Devices',
            'Facilities',
            'Software',
            'Infrastructure',
            'Monitoring',
          ],
          datasets: [{
            label: 'My First Dataset',
            data: [10000, 20000, 15000],
            backgroundColor: ["#830051", "#FDB752", "#E1C1D4"],

            // hoverOffset: 4,
          }]
        },
        options: {
          cutout: '70%',
          responsive: true,
          maintainAspectRatio: false,
          plugins: {
            tooltip: {
              callbacks: {
                label: function (tooltipItem) {
                  let label = tooltipItem.chart.data.labels[tooltipItem.dataIndex];
                  let value = tooltipItem.raw;
                  // Format value sebagai string uang
                  return label + ': Rp' + value.toLocaleString('id-ID');
                }
              }
            },
            legend: {
              display: false
            }
          }
        }
      });
      const ctx2 = document.getElementById('myChart2');
      // console.log(dataAggregate.value)
      new Chart(ctx2, {
        type: 'doughnut',
        data: {
          labels: [
            'Laki Laki',
            'Perempuan',
          ],
          datasets: [{
            label: 'My First Dataset',
            data: [10000, 20000],
            backgroundColor: ["#830051", "#FDB752", "#E1C1D4"],

            // hoverOffset: 4,
          }]
        },
        options: {
          cutout: '70%',
          responsive: true,
          maintainAspectRatio: false,
          plugins: {
            tooltip: {
              callbacks: {
                label: function (tooltipItem) {
                  let label = tooltipItem.chart.data.labels[tooltipItem.dataIndex];
                  let value = tooltipItem.raw;
                  // Format value sebagai string uang
                  return label + ': Rp' + value.toLocaleString('id-ID');
                }
              }
            },
            legend: {
              display: false
            }
          }
        }
      });
      const ctx4 = document.getElementById('myChart4');
      chartInstance.value = new Chart(ctx4, {
        type: 'bar',
        data: {
          labels: ['Jakarta', 'Bandung', 'Palembang', 'Semarang', 'Garut', 'Lampung', 'Yogyakarta'], // Labels dari kota
          datasets: [
            {
              label: 'Data', // Judul dataset
              data: [80, 50, 30, 40, 10, 20, 70], // Data dummy sesuai visualisasi yang Anda tunjukkan
              backgroundColor: '#AC3D68', // Warna bar yang sesuai dengan gambar
              borderColor: '#AC3D68',
              borderWidth: 1,
              barThickness: 20,
              borderRadius: { topLeft: 5, topRight: 5 },
            }
          ]
        },
        options: {
          indexAxis: 'y',
          responsive: true,
          maintainAspectRatio: false,
          scales: {
            y: {
              beginAtZero: true, // Memulai skala Y dari 0
              grid: {
                color: '#E9E9E9' // Warna garis grid tetap
              }
            },
            x: {
              grid: {
                display: false // Menonaktifkan garis grid pada sumbu X
              }
            }
          },
          plugins: {
            legend: {
              display: false // Menonaktifkan legenda
            },
            tooltip: {
              enabled: false, // Mengaktifkan tooltip
              mode: 'index',
              intersect: false
            }
          }
        }
      });
      const ctx5 = document.getElementById('myChart5');
      chartInstance.value = new Chart(ctx5, {
        type: 'bar',
        data: {
          labels: ['Jakarta', 'Bandung', 'Palembang', 'Semarang', 'Garut', 'Lampung', 'Yogyakarta'], // Labels dari kota
          datasets: [
            {
              label: 'Data', // Judul dataset
              data: [80, 50, 30, 40, 10, 20, 70], // Data dummy sesuai visualisasi yang Anda tunjukkan
              backgroundColor: '#AC3D68', // Warna bar yang sesuai dengan gambar
              borderColor: '#AC3D68',
              borderWidth: 1,
              barThickness: 20,
              borderRadius: { topLeft: 5, topRight: 5 },
            }
          ]
        },
        options: {
          // indexAxis: 'y',
          responsive: true,
          maintainAspectRatio: false,
          scales: {
            y: {
              beginAtZero: true, // Memulai skala Y dari 0
              grid: {
                color: '#E9E9E9' // Warna garis grid tetap
              }
            },
            x: {
              grid: {
                display: false // Menonaktifkan garis grid pada sumbu X
              }
            }
          },
          plugins: {
            legend: {
              display: false // Menonaktifkan legenda
            },
            tooltip: {
              enabled: false, // Mengaktifkan tooltip
              mode: 'index',
              intersect: false
            }
          }
        }
      });


    })
    onBeforeUnmount(() => {
      if (chartInstance.value) {
        chartInstance.value.destroy();
        chartInstance.value = null;
      }
    });


    return {
      totalPasien,
      rataSkor,
      pendingVerifikasi,
      patientTiering,
      strRentangUmur,
      settings,
      lottie,
      caution,
    }
  },
}
</script>
<style lang="scss">
@import '../styleViews.scss';
@import url('https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300..900;1,300..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

.label {
  font-size: 16px;
  color: #666;
}

.progress-bar {
  background-color: #f0f0f0;
  border-radius: 10px;
  margin: 10px 0;
}

.progress {
  height: 20px;
  border-radius: 10px 0 0 10px;
  color: white;
  display: flex;
  align-items: center;
  padding-left: 10px;
}

.tieringList {
  font-family: 'Montserrat';
  font-size: 32px;
  line-height: 0.8;
  font-weight: 600;
}

.text-custom {
  font-style: 'Montserrat';
  font-weight: 500;
  font-size: 24px;
}

.font-custom {
  font-style: 'Montserrat';
  font-optical-sizing: auto;
  font-weight: 600;
  font-style: normal;
}

.font-custom2 {
  font-style: 'Montserrat';
  font-optical-sizing: auto;
  font-weight: 300;
  font-style: normal;
}

.font-custom3 {
  font-family: "Montserrat", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
  color: #8D8A8E;
}

.number-custom {
  font-style: 'Monstserrat';
  font-weight: 700;
  font-size: 40px;
}
</style>